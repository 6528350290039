<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Detail KS Mitra -->
    <b-modal
      id="modal-detail-mitra"
      v-model="isModalMitra"
      size="lg"
      centered
      title="Detail Data KS Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label for="kodeMitra">Kode Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="kodeMitra"
            v-model="form.kodeMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group>
          <label for="nomorPksMitra">Nomor KS Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nomorPksMitra"
            v-model="form.nomorPksMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group>
          <label for="penandatanganMitra">Pihak Mitra (Penandatangan):</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="penandatanganMitra"
            v-model="form.penandatanganMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanMitra">Jabatan Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="jabatanMitra"
            v-model="form.jabatanMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group label="Email Mitra:" label-for="emailMitra">
          <input-primary
            type="email"
            maxLength="100"
            v-model="form.emailMitra"
            lowerCase
            required
            id="emailMitra"
            isDetail
          />
        </b-form-group>
        <b-form-group label="Penghubung Mitra:" label-for="idPenghubungMitra">
          <input-primary
            id="idPenghubungMitra"
            v-model="form.idPenghubungMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group label="Telepon Mitra:" label-for="telpMitra">
          <input-primary
            id="telpMitra"
            v-model="form.telpMitra"
            required
            phone
            isDetail
          />
        </b-form-group>
        <!-- <b-form-group>
          <label for="berlakuPks">Tanggal Berlaku KS:</label
          ><span class="text-danger ml-1">*</span>
          <flat-pickr
            id="berlakuPks"
            class="form-control cursor-not-allowed"
            :config="basicConfig"
            v-model="form.berlakuPks"
            placeholder="Tanggal Berlaku KS"
            required
            disabled
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label for="berakhirPks">Tanggal Berakhir KS:</label
          ><span class="text-danger ml-1">*</span>
          <flat-pickr
            id="berakhirPks"
            class="form-control cursor-not-allowed"
            :config="basicConfig"
            v-model="form.berakhirPks"
            placeholder="Tanggal Berakhir KS"
            required
            disabled
          ></flat-pickr>
        </b-form-group> -->
        <b-row class="mt-5">
          <b-btn class="btn-block" @click="closeModalMitra">Kembali</b-btn>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Detail KS Mitra -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Detail Data PKS</h4>
          </template>
          <template v-slot:body>
            <b-row class="w-full mt-lg-2">
              <b-row
                class="ml-2 pl-1"
                v-if="
                  dataPks.dataAdendum &&
                    dataPks.dataAdendum[0] &&
                    dataPks.dataAdendum[0].cetakan
                "
              >
                <span>Pilih Cetakan:</span>
                <b-col
                  v-for="cetaks in dataPks.dataAdendum"
                  :key="cetaks.idPksAdendum"
                >
                  <b-button
                    class="rounded mr-2"
                    variant="primary"
                    size="sm"
                    @click="getAdendum(cetaks.idPksAdendum, cetaks)"
                    >Cetakan {{ cetaks.cetakan }}</b-button
                  >
                </b-col>
              </b-row>
              <b-col>
                <b-button
                  class="rounded px-4 float-right"
                  variant="secondary"
                  size="sm"
                  @click="$router.go(-1)"
                  ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
                >
              </b-col>
            </b-row>
            <div class="align-items-center mt-lg-3">
              <b-form>
                <b-form-group label="Kode PKS:" label-for="kodePks">
                  <input-primary
                    id="kodePks"
                    v-model="form.kodePks"
                    required
                    max-length="100"
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="kodeJenisPks">Kode Jenis KS:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="kodeJenisPks"
                    v-model="form.kodeJenisPks"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="idTipeBeasiswa">Tipe Beasiswa:</label
                  ><span
                    class="text-danger ml-1"
                    v-show="dataPks.idJenisPks == 1"
                    >*</span
                  >
                  <div class="text-secondary">
                    * <b>Tidak Langsung</b>: Pembayaran ke UT melalui pemberi
                    beasiswa<br />** <b>Langsung</b>: Pembayaran ke UT dilakukan
                    oleh mahasiswa
                  </div>
                  <input-primary
                    id="idTipeBeasiswa"
                    v-model="form.idTipeBeasiswa"
                    required
                    isDetail
                  />
                </b-form-group>
                <!-- <b-form-group label="MOU Jika Ada:" label-for="mou">
                  <input-primary
                    id="mou"
                    v-model="form.mou"
                    required
                    isDetail
                  />
                </b-form-group> -->
                <b-form-group>
                  <label for="namaPks">Judul KS:</label
                  ><span class="text-danger ml-1">*</span>
                  <b-form-textarea
                    id="namaPks"
                    class="cursor-not-allowed"
                    v-model="form.namaPks"
                    rows="3"
                    disabled
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group>
                  <label for="unitKs">Unit Penyelenggara:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="unitKs"
                    v-model="form.unitKs"
                    required
                    isDetail
                  />
                </b-form-group>
                <b-form-group>
                  <label for="unitPelaksana">Unit Pelaksana:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-datalists
                    multiple
                    :closeOnSelect="false"
                    id="unitPelaksana"
                    ref="refUnitPelaksana"
                    class="w-full text-sm"
                    v-model="form.unitPelaksana"
                    label="namaUnit"
                    required
                    :options="listDataUnitPks || []"
                    :reduce="listDataUnitPks => listDataUnitPks.idUnit"
                    isDetail
                  >
                    <template v-slot:option="option">
                      <div class="flex justify-between p-1">
                        {{ option.kodeUnit }} | {{ option.namaUnit }}
                      </div>
                    </template>
                  </input-datalists>
                </b-form-group>
                <!-- <b-form-group label="Adendum Jika Ada:" label-for="adendum">
                  <input-primary
                    id="adendum"
                    v-model="form.adendum"
                    max-length="100"
                    isDetail
                  />
                </b-form-group> -->
                <!-- <b-form-group>
                  <label for="tglPks">Tanggal KS:</label
                  ><span class="text-danger ml-1">*</span>
                  <flat-pickr
                    id="tglPks"
                    class="form-control bg-light cursor-not-allowed"
                    :config="basicConfig"
                    v-model="form.tglPks"
                    placeholder="Tanggal KS"
                    disabled
                  ></flat-pickr>
                </b-form-group> -->
                <b-row class="mt-4">
                  <!-- Kategori KS -->
                  <b-col>
                    <h4 class="mb-2">KATEGORI KS</h4>
                    <b-form-group>
                      <label for="kodeKategori">Kategori KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="kodeKategori"
                        v-model="form.kodeKategori"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="kodeSubKategori">Sub Kategori KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="kodeSubKategori"
                        v-model="form.kodeSubKategori"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="ruangLingkup">Ruang Lingkup KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <b-form-textarea
                        id="ruangLingkup"
                        class="cursor-not-allowed"
                        v-model="form.ruangLingkup"
                        rows="3"
                        disabled
                      ></b-form-textarea>
                    </b-form-group>
                    <h6 class="mb-2">
                      Luaran:<span class="text-danger ml-1">*</span>
                    </h6>
                    <b-form-row>
                      <b-col lg="4">
                        <input-primary
                          id="volume"
                          v-model="form.volume"
                          required
                          type="number"
                          placeholder="Volume"
                          isDetailPrepend
                        />
                      </b-col>
                      <b-col>
                        <input-primary
                          id="satuanVolume"
                          v-model="form.satuanVolume"
                          required
                          type="text"
                          placeholder="@"
                          isDetailPrepend
                        />
                      </b-col>
                    </b-form-row>
                  </b-col>
                  <!-- Tanggal KS -->
                  <b-col>
                    <h4 class="mb-2">TANGGAL KS</h4>
                    <b-form-group>
                      <label for="tglPks">Tanggal KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="tglPks"
                        ref="refTglPks"
                        class="form-control cursor-not-allowed"
                        :config="basicConfig"
                        v-model="form.tglPks"
                        placeholder="Tanggal KS"
                        disabled
                      ></flat-pickr>
                    </b-form-group>
                    <!-- Tanggal Berlaku KS -->
                    <b-form-group>
                      <label for="berlakuPks">Tanggal Berlaku KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="berlakuPks"
                        class="form-control cursor-not-allowed"
                        :config="basicConfig"
                        v-model="form.berlakuPks"
                        placeholder="Tanggal Berlaku KS"
                        required
                        disabled
                      ></flat-pickr>
                    </b-form-group>
                    <!-- Tanggal Berakhir KS -->
                    <b-form-group>
                      <label for="berakhirPks">Tanggal Berakhir KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="berakhirPks"
                        ref="refBerakhirPks"
                        class="form-control cursor-not-allowed"
                        :config="basicConfig"
                        v-model="form.berakhirPks"
                        placeholder="Tanggal Berakhir KS"
                        disabled
                      ></flat-pickr>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- KS UT -->
                  <b-col>
                    <h4 class="mb-2">KS UT</h4>
                    <b-form-group>
                      <label for="nomorPksUt">Nomor KS UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nomorPksUt"
                        v-model="form.nomorPksUt"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="penandatanganUt"
                        >Pihak UT (Penandatangan):</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="penandatanganUt"
                        v-model="form.penandatanganUt"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanUt">Jabatan UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanUt"
                        v-model="form.jabatanUt"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group label="Email UT:" label-for="emailUt">
                      <input-primary
                        type="email"
                        maxLength="100"
                        v-model="form.emailUt"
                        lowerCase
                        required
                        id="emailUt"
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="penghubungUt">Penghubung UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="penghubungUt"
                        v-model="form.penghubungUt"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="telpUt">Telepon UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="telpUt"
                        v-model="form.telpUt"
                        required
                        phone
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                  <!-- KS Beasiswa -->
                  <b-col>
                    <h4 class="mb-2">KS BEASISWA</h4>
                    <b-form-group
                      label="Jenis Beasiswa:"
                      label-for="jenisBeasiswa"
                    >
                      <input-primary
                        id="jenisBeasiswa"
                        v-model="form.jenisBeasiswa"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jumlahMahasiswa">Volume:</label
                      ><span
                        class="text-danger ml-1"
                        v-show="dataPks.idJenisPks == 1"
                        >*</span
                      >
                      <input-primary
                        id="jumlahMahasiswa"
                        v-model="form.jumlahMahasiswa"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="satuan">Satuan:</label
                      ><span
                        class="text-danger ml-1"
                        v-show="dataPks.idJenisPks == 1"
                        >*</span
                      >
                      <input-primary
                        id="satuan"
                        v-model="form.satuan"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="masaAwal">Masa Registrasi Mulai:</label
                      ><span
                        class="text-danger ml-1"
                        v-show="dataPks.idJenisPks == 1"
                        >*</span
                      >
                      <input-primary
                        id="masaAwal"
                        v-model="form.masaAwal"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="masaAkhir">Masa Registrasi Sampai:</label
                      ><span
                        class="text-danger ml-1"
                        v-show="dataPks.idJenisPks == 1"
                        >*</span
                      >
                      <input-primary
                        id="masaAkhir"
                        v-model="form.masaAkhir"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- Nominal KS -->
                  <b-col>
                    <h4 class="mb-2">NOMINAL KS</h4>
                    <b-form-group>
                      <label for="nominalPks">Nominal KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <div class="text-secondary">
                        * Ditulis dalam format ribuan (X 1000). Contoh :
                        1.250.000 menjadi 1.250
                      </div>
                      <input-primary
                        id="nominalPks"
                        v-model="form.nominalPks"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group>
                    <!-- <b-form-group>
                      <label for="realisasiPks">Realisasi KS:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="realisasiPks"
                        v-model="form.realisasiPks"
                        required
                        type="number"
                        isDetail
                      />
                    </b-form-group> -->
                    <b-form-group>
                      <label for="caraPembayaran">Cara Pembayaran:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="caraPembayaran"
                        v-model="form.caraPembayaran"
                        required
                        isDetail
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Lokasi Dokumen -->
                  <b-col>
                    <h4 class="mb-2">LOKASI DOKUMEN</h4>
                    <b-form-group>
                      <label for="lokasiDokumenFisik"
                        >Lokasi Dokumen Fisik:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        class="mb-2"
                        id="lokasiDokumenFisik"
                        v-model="form.lokasiDokumenFisik"
                        required
                        isDetail
                      />
                    </b-form-group>
                    <b-form-group
                      :class="
                        dataDokumenKs ? 'cursor-pointer' : 'cursor-not-allowed'
                      "
                      v-b-tooltip="
                        dataDokumenKs
                          ? 'Download Dokumen'
                          : 'File tidak ditemukan'
                      "
                    >
                      <label for="lokasiDokumenSoft">Lokasi Dokumen Soft:</label
                      ><span class="text-danger ml-1">*</span>
                      <!-- <div class="w-full"> -->
                      <b-button
                        v-if="dataDokumenKs"
                        class="px-4 ml-4"
                        variant="primary"
                        @click="showDokumenKs"
                        ><i class="ri-search-line mr-1"></i>Download
                        Dokumen</b-button
                      >
                      <b-button
                        v-else
                        class="px-4 ml-4"
                        variant="default"
                        disabled
                        ><i class="ri-search-line mr-1"></i>Download
                        Dokumen</b-button
                      >
                      <!-- </div> -->
                      <!-- <b-form-textarea
                        id="lokasiDokumenSoft"
                        class="cursor-not-allowed"
                        v-model="form.lokasiDokumenSoft"
                        rows="3"
                        required
                        disabled
                      ></b-form-textarea> -->
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- KS Mitra -->
                <b-row class="mt-2">
                  <b-col>
                    <h4 class="mb-2">KS MITRA</h4>
                    <b-form-group
                      v-for="items in listInputMitra"
                      :key="items.idMitra"
                    >
                      <label for="namaMitra">Mitra:</label
                      ><span class="text-danger ml-1">*</span>
                      <b-row>
                        <b-col lg="11">
                          <input-primary
                            id="namaMitra"
                            v-model="items.namaMitra"
                            required
                            isDetail
                          />
                        </b-col>
                        <b-col class="align-self-center" lg="1">
                          <b-button
                            class="btn-block"
                            variant="primary"
                            @click="showDetailMitra(items.idMitra)"
                            ><i class="ri-search-line ml-2"></i
                          ></b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Validasi -->
                <b-row class="mt-2">
                  <b-col>
                    <h4 class="mb-2">VALIDASI DATA</h4>
                    <b-form-group>
                      <input-datalists
                        id="idStatusValidasi"
                        class="w-full text-sm"
                        placeholder="Pilih Validasi"
                        v-model="form.idStatusValidasi"
                        label="keteranganValidasi"
                        required
                        :options="listDataValidasi || []"
                        :reduce="
                          listDataValidasi => listDataValidasi.idValidasi
                        "
                        :clearable="false"
                      >
                        <template #header>
                          <label class="block"
                            >Status Validasi:
                            <span class="text-danger">*</span></label
                          >
                        </template>
                        <template v-slot:option="option">
                          <div class="flex justify-between p-1">
                            {{
                              option.keteranganValidasi == null
                                ? `Data tidak ditemukan`
                                : option.idValidasi +
                                  ` | ` +
                                  option.keteranganValidasi
                            }}
                          </div>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-form-group v-if="form.idStatusValidasi == 2">
                      <label for="idKeteranganValidasi">Alasan Pemutusan:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="idKeteranganValidasi"
                        placeholder="Masukkan alasan pemutusan"
                        v-model="form.idKeteranganValidasi"
                        required
                        type="textarea"
                        max-length="255"
                        rows="3"
                      />
                    </b-form-group>
                    <!-- <b-form-group v-else>
                      <label for="idStatusValidasi">Status Validasi:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="idStatusValidasi"
                        v-model="form.idStatusValidasi"
                        required
                        isDetail
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <b-button
                      class="btn-block rounded mx-1"
                      variant="secondary"
                      size="sm"
                      @click="$router.go(-1)"
                      ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      class="btn-block rounded mx-1"
                      variant="primary"
                      v-b-tooltip="'Validasi data'"
                      size="sm"
                      @click="validasiData"
                      :disabled="!isValidForm"
                      ><i class="ri-check-line m-0"></i>Validasi</b-button
                    >
                  </b-col>
                </b-row>
                <!-- <b-row class="mt-5" v-else>
                  <b-button
                    class="btn-block rounded mx-1"
                    variant="secondary"
                    size="sm"
                    @click="$router.go(-1)"
                    ><i class="ri-arrow-left-line m-0"></i>Kembali</b-button
                  >
                </b-row> -->
              </b-form>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'kerjasama.pks.detail',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.idStatusValidasi && this.form.idStatusValidasi === 2
          ? this.form.idKeteranganValidasi
          : !this.form.idKeteranganValidasi != null
      // const statusGagalValidasi = this.form.idKeteranganValidasi != null
      return status
    }
  },
  mounted() {
    if (!this.$route.params.idPks) this.$router.go(-1)
    this.form.idPks = this.$route.params.idPks
    this.statusValidasi = this.$route.params.statusValidasi
    this.keteranganValidasi = this.$route.params.keteranganValidasi
    this.innitData()
  },
  methods: {
    /**
     * Get data Detail Kerjasama PKS
     */
    async getDataDetailPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataTransaksiPks?idPks=${this.form.idPks}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataPks = res.data[0]
          this.form = { ...this.dataPks }
          /** Membuat array mitra */
          if (this.dataPks.dataMitra.length > 0) {
            this.listInputMitra = [...this.dataPks.dataMitra]
          }
          /** Custom value untuk input datalist */
          this.form.unitPelaksana = this.dataPks?.dataUnitPelaksana.map(
            up => up.idUnitPelaksana
          )
          this.form.kodeJenisPks =
            this.dataPks.kodeJenisPks + ' | ' + this.dataPks.namaJenisPks
          this.form.unitKs =
            this.dataPks.kodeUnitPks + ' | ' + this.dataPks.namaUnitPks
          this.form.kodeKategori =
            this.dataPks.kodeKategoriPks + ' | ' + this.dataPks.namaKategoriPks
          this.form.kodeSubKategori =
            this.dataPks.kodeSubKategoriPks +
            ' | ' +
            this.dataPks.namaSubKategoriPks
          // this.form.berlakuPks = this.dataPks.dataMitra[0].berlakuPks
          // this.form.berakhirPks = this.dataPks.dataMitra[0].berakhirPks
          // Khusus Beasiswa
          if (this.dataPks.idJenisPks === 1) {
            this.form.idTipeBeasiswa =
              this.dataPks.idTipeBeasiswa === 1 ? 'LANGSUNG' : 'TIDAK LANGSUNG'
          } else {
            this.form.idTipeBeasiswa = null
            this.form.jenisBeasiswa = null
            this.form.jumlahMahasiswa = null
            this.form.satuan = null
            this.form.masaAwal = null
            this.form.masaAkhir = null
          }
          /** Penghubung mitra */
          const idPenghubungMitra = this.listInputMitra[0].idPenghubungMitra
          this.listInputMitra[0].penghubungMitra.map(a => {
            if (a.idPenghubungMitra === idPenghubungMitra) {
              this.form.emailMitra = a.emailPenghubungMitra
              this.form.idPenghubungMitra = a.namaPenghubungMitra
              this.form.telpMitra = a.telpPenghubungMitra
            }
          })
          // Dokumen KS
          this.getDokumenKs_()
          console.log('data: ', this.dataPks)
          /** Validasi */
          if (this.keteranganValidasi) {
            this.form.idKeteranganValidasi = this.keteranganValidasi
          }
        })
        .catch(err => {
          this.$router.push({
            name: 'kerjasama.jenisDokumen'
          })
          this.$toast.error('Terjadi kesalahan pada sistem')
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => List data Unit PKS
     */
    async getListDataUnitPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=listUnit`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUnitPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Get List data Validasi
     */
    async getListDataValidasi_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=validasi`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataValidasi = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Input Cetakan Adendum
     */
    async getAdendum(idCetakan, data) {
      this.showCetakan = 'cetakan ke-' + data.cetakan
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDataTransaksiPks?idPks=${idCetakan}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataPks = res.data[0]
          this.form = { ...this.dataPks }
          /** Membuat array mitra */
          if (this.dataPks.dataMitra.length > 0) {
            this.listInputMitra = [...this.dataPks.dataMitra]
          }
          /** Custom value untuk input datalist */
          this.form.unitPelaksana = this.dataPks?.dataUnitPelaksana.map(
            up => up.idUnitPelaksana
          )
          this.form.kodeJenisPks =
            this.dataPks.kodeJenisPks + ' | ' + this.dataPks.namaJenisPks
          this.form.unitKs =
            this.dataPks.kodeUnitPks + ' | ' + this.dataPks.namaUnitPks
          this.form.kodeKategori =
            this.dataPks.kodeKategoriPks + ' | ' + this.dataPks.namaKategoriPks
          this.form.kodeSubKategori =
            this.dataPks.kodeSubKategoriPks +
            ' | ' +
            this.dataPks.namaSubKategoriPks
          // this.form.berlakuPks = this.dataPks.dataMitra[0].berlakuPks
          // this.form.berakhirPks = this.dataPks.dataMitra[0].berakhirPks
          // Khusus Beasiswa
          if (this.dataPks.idJenisPks === 1) {
            this.form.idTipeBeasiswa =
              this.dataPks.idTipeBeasiswa === 1 ? 'LANGSUNG' : 'TIDAK LANGSUNG'
          } else {
            this.form.idTipeBeasiswa = null
            this.form.jenisBeasiswa = null
            this.form.jumlahMahasiswa = null
            this.form.satuan = null
            this.form.masaAwal = null
            this.form.masaAkhir = null
          }
          /** Penghubung mitra */
          const idPenghubungMitra = this.listInputMitra[0].idPenghubungMitra
          this.listInputMitra[0].penghubungMitra.map(a => {
            if (a.idPenghubungMitra === idPenghubungMitra) {
              this.form.emailMitra = a.emailPenghubungMitra
              this.form.idPenghubungMitra = a.namaPenghubungMitra
              this.form.telpMitra = a.telpPenghubungMitra
            }
          })
          // Dokumen KS
          this.getDokumenKs_()
          console.log('data: ', this.dataPks)
          /** Validasi */
          if (this.keteranganValidasi) {
            this.form.idKeteranganValidasi = this.keteranganValidasi
          }
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      this.$toast.info('Menampilkan ' + this.showCetakan)
      return result
    },
    /**
     * Input Mitra
     */
    showDetailMitra(idMitra) {
      this.listInputMitra.map(m => {
        if (idMitra === m.idMitra) {
          this.form.idMitra = m.idMitra
          this.form.kodeMitra = m.kodeMitra
          this.form.namaMitra = m.namaMitra
          this.form.nomorPksMitra = m.nomorPksMitra
          this.form.penandatanganMitra = m.penandatanganMitra
          this.form.jabatanMitra = m.jabatanMitra
          // this.form.berlakuPks = m.berlakuPks
          // this.form.berakhirPks = m.berakhirPks
        }
      })
      this.isModalMitra = true
    },
    closeModalMitra() {
      this.isModalMitra = false
    },
    /**
     * Validasi Data
     */
    async validasiData() {
      console.log('form: ', this.form)
      // if (this.form.idStatusValidasi === 1) {
      //   this.form.keteranganValidasi = 'Sudah Validasi'
      // } else if (this.form.idStatusValidasi === 2) {
      //   this.form.keteranganValidasi = 'Gagal Validasi'
      // } else {
      //   this.form.keteranganValidasi = 'Belum Validasi'
      // }
      const bodyFormData = new FormData()
      bodyFormData.append('idPks', this.form.idPks)
      bodyFormData.append('idStatusValidasi', this.form.idStatusValidasi)
      bodyFormData.append(
        'keteranganValidasi',
        this.form.idStatusValidasi === 2 ? this.form.idKeteranganValidasi : null
      )

      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/ubahValidasi`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$router.push({ name: 'menuKhusus.verifikasiDokumen' })
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('Data gagal divalidasi')
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => Dokumen KS
     */
    async getDokumenKs_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenPks?lokasiDokumenSoft=${this.dataPks.lokasiDokumenSoft}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.dataDokumenKs = res.data
        })
        .catch(err => {
          this.dataDokumenKs = null
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * SHOW => Dokumen KS
     */
    async showDokumenKs() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getDokumenPks?lokasiDokumenSoft=${this.dataPks.lokasiDokumenSoft}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          var url = window.URL.createObjectURL(res.data)
          var a = document.createElement('a')
          a.href = url
          a.download = `Dokumen_PKS_${this.dataPks.kodePks}_${this.dataPks.nomorPksUt}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error('File tidak ditemukan')
          throw new Error(err)
        })
      return result
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get data Detail Kerjasama PKS */
      this.getDataDetailPks_()
      /** Get List data */
      await Promise.all([
        this.getListDataUnitPks_(),
        this.getListDataValidasi_()
      ])
    }
  },
  data() {
    return {
      // Others
      statusValidasi: null,
      keteranganValidasi: null,
      dataDokumenKs: null,
      showCetakan: null,
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // Mitra
      isModalMitra: false,
      kodeMitra: null,
      // List Data
      listInputMitra: [],
      listDataUnitPks: [],
      listDataValidasi: [],
      // Form
      form: {
        idPks: null,
        kodePks: null,
        kodeJenisPks: null,
        idTipeBeasiswa: null,
        // mou: null,
        namaPks: null,
        unitKs: null,
        unitPelaksana: null,
        // Kategori KS
        kodeKategori: null,
        kodeSubKategori: null,
        ruangLingkup: null,
        volume: null,
        satuanVolume: null,
        // Tanggal KS
        tglPks: null,
        berlakuPks: null,
        berakhirPks: null,
        // jangkaWaktu: null,
        // KS UT
        nomorPksUt: null,
        penandatanganUt: null,
        jabatanUt: null,
        emailUt: null,
        penghubungUt: null,
        telpUt: null,
        // KS Beasiswa
        jenisBeasiswa: null,
        jumlahMahasiswa: null,
        satuan: null,
        masaAwal: null,
        masaAkhir: null,
        // Nominal KS
        nominalPks: null,
        // realisasiPks: null,
        caraPembayaran: null,
        // Lokasi Dokumen
        lokasiDokumenFisik: null,
        lokasiDokumenSoft: null,
        // KS Mitra
        idMitra: null,
        kodeMitra: null,
        namaMitra: null,
        nomorPksMitra: null,
        penandatanganMitra: null,
        jabatanMitra: null,
        emailMitra: null,
        idPenghubungMitra: null,
        telpMitra: null,
        // Validasi
        idStatusValidasi: null,
        idKeteranganValidasi: null
      },
      // Data PKS
      dataPks: {}
    }
  }
}
</script>
